import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import PrinterMap from './PrinterMap';

// HOC to get router params
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class SinglePrinterMapWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      printer: null,
      error: false
    };
  }

  getPrinter() {
    const apiUrl = process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PREFIX + '/printers/' + this.props.params.printerId;
    
    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({ printer: json, loading: false });
      })
      .catch(() => {
        this.setState({ error: true, loading: false });
      });
  }

  componentDidMount() {
    this.getPrinter();
  }

  render() {
    const { loading, printer, error } = this.state;

    if (loading) return null;
    if (error) return <div>Error loading printer</div>;

    return (
      <PrinterMap 
        printers={[printer]} 
        apiKey={process.env.REACT_APP_GMAPS_EMBED_API_KEY} 
      />
    );
  }
}

export default withParams(SinglePrinterMapWidget);